@import '../scss/index.scss';


.layout-admin {
    transition: margin-left 0.2s;

    &__header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 0;
        height: 65px;
    }
    &__content{
        padding: 90px 25px 25px 25px;
        min-height: calc(100vh - 65px);
    }
    &__footer{
        padding: 20px;
    }
}