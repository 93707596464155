.list-vehicles{
    &__header{
        display:flex;
        margin-bottom: 20px;
        justify-content:flex-end;
    }
 
    &__table{

        button{
            margin-left: 5px;
        }
    }
}