@import '../../../scss/index.scss';


.menu-top{
    display: flex;

    button{
        background-color: transparent;
        color: $font-light;
        border: 0;

        &:hover{
            color: $primary-color;
        }
        &:focus,
        &:active{
            color: $font-light;
        }

        &.off:hover{
            color: $error;
        }
       
    }

    &__left{
        &-logo{
            width: 170px;
            padding: 0 10px;
        } 
     }
     &__right{
         position: absolute;
         right: 0;
     }
}