.edit-vehicle-form{

    .form-edit{
        text-align: center;


        .ant-form-item{
            margin-top: 5px;
            margin-bottom: 5px;
        }


        .btn-submit{
            margin-top: 10px;
            width: 80%;
        }
    }
}